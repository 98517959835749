import * as React from "react";
import { useState } from "react";
import { Header, Container, Segment, Icon, Form, FormProps, Message } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const NewsletterPage = () => {
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event: (React.ChangeEvent | React.FormEvent)) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value.trim(),
    });
  };

  const handleSubmitEmailSignup = (ev: React.FormEvent) => {
    setSubmitted(true);
    ev.preventDefault();
    const submitData = new URLSearchParams();
    submitData.append("email", email);
    const formResult = fetch(
      "https://formspree.io/f/mjvpezzo",
      {
        body: submitData,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
      },
    );
    formResult.then((result: any) => {
      setSuccess(true);
    });
  };

  return (
    <Container text>
      <Segment vertical>
        <Header as="h2">
          <Icon name="newspaper outline" />
          <Header.Content>
            Newsletter
          </Header.Content>
        </Header>
        <p>
          Sign up to get an update when getmeoutof.tech launches.
        </p>
        <Form
          onSubmit={handleSubmitEmailSignup}
          success={success}
        >
          <Form.Input fluid onChange={handleChange} name="email" label="Email" placeholder="Email"></Form.Input>
          <Form.Button disabled={submitted}>Submit</Form.Button>
          <Message
            success
            header="Signup received"
            content="Look for a confirmation email in your inbox"
          />
        </Form>
      </Segment>
    </Container>
  );
};

export default withLayout(NewsletterPage);
